.mission {
    background-image: linear-gradient(350deg, #da0a43 32.1%, #500a31 100% );
    display: flex;
    font-family: 'Montserrat', sans-serif;
    padding-top: 10%;
    padding-bottom: 5%;
    margin-bottom: 0;
    padding-left: 10%;
    padding-right: 10%;
}

.arrow-mission {
    height: 180px;
    width: 250px;
}

.mission-txt {
    color: white;
    margin-left: 5%;
    width: 48%;
}

.mission-txt h1 {
    font-weight: 400;
    font-size: 2.5em;
}

.mission-txt .mission-bold {
    font-weight: 700;
}

.mission-txt p {
    font-size: 12px;
    width: 85%;
    padding-top: 5%;
    /* text-align: justify; */
}

@media (max-width: 320px) {
    .mission {
        flex-direction: column;
        padding-top: 20%;
   }

   .mission-txt {
        width: 80%;
        padding-top: 10%;
    }

    .mission-txt h1 {
        font-size: 2.25em;
    }

    .mission-txt p {
        width: 100%;
    }
}

@media (min-width: 321px) and (max-width: 480px) {
   .mission {
        flex-direction: column;
        padding-top: 20%;
   }

   .mission-txt {
        width: 80%;
        padding-top: 10%;
    }

    .mission-txt p {
        width: 100%;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .mission {
        flex-direction: column;
        padding-top: 20%;
   }

   .mission-txt {
        width: 80%;
        padding-top: 10%;
    }

    .mission-txt p {
        width: 100%;
    }
   
}

@media (min-width: 769px) and (max-width: 1280px) {

}

@media (min-width: 1281px) {

}

@media (min-width: 1920px) {
 
}