#popover-contained {
  /* border: 1px solid rgb(0, 0, 0); */
  border-radius: 0rem;
  background-color: #FFFFFF;
  /* box-shadow: -10px -10px 15px rgb(0 0 0 / 30%); */
  z-index: 900;
}

#popover-contained .socialicons-body {
  padding: 0.0rem;
  width: 10rem;
  border-radius: 0rem;
}

#popover-contained .socialicons-body .btn {
  padding: 0rem;
  border: 0rem;
}

#popover-contained .socialicons-body .btn-primary:hover {
  background-color: #fff;
}


#popover-contained button {
  background-color: #FFFFFF;
  margin: 0px;
  width: 100%;
}

#popover-contained .popover-content {
  background-color: #FFFFFF;
  color: #000000;
  padding: 0px;
}

#popover-contained .arrow {
  border-right-color: rgb(255, 255, 255) !important;
}

button:focus {
  box-shadow:none !important;
}