*{
    box-sizing: border-box;
    margin: 0;
}

body {
    font-family: 'Montserrat', sans-serif;
}

#app-container{
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-position-y: -250px;

}

#content{
    display: flex;
    flex-direction: column;
    justify-content: center;    
    margin: auto 10%;
}

@media (max-width: 320px) {
    #app-container{
        background:linear-gradient(345deg, rgba(138,138,138,0)  0%, rgba(138,138,138,0)  31.9%,  #da0a43 32.1%, #500a31 100%);
        height: 1150px;
    }
}

@media (min-width: 321px) and (max-width: 480px) {
    #app-container{
        background:linear-gradient(345deg, rgba(138,138,138,0)  0%, rgba(138,138,138,0)  31.9%,  #da0a43 32.1%, #500a31 100%);
        height: 1120px;
    }
}

@media (min-width: 481px) and (max-width: 958px) {
    #app-container{
        background:linear-gradient(345deg, rgba(138,138,138,0)  0%, rgba(138,138,138,0)  31.9%,  #da0a43 32.1%, #500a31 100%);
        height: 1100px;
    }
}

@media (min-width: 959px) and (max-width: 1040px) {
    #app-container{
        background:linear-gradient(351deg, rgba(138,138,138,0)  0%, rgba(138,138,138,0)  31.9%,  #da0a43 32.1%, #500a31 100%);
        height: 900px;
    }
}

@media (min-width: 1041px) {
    #app-container{
        background:linear-gradient(351deg, rgba(138,138,138,0)  0%, rgba(138,138,138,0)  31.9%,  #da0a43 32.1%, #500a31 100%);
        height: 900px;
    }

    
}

@media (min-width: 1920px) {
    #app-container{
        background:linear-gradient(351deg, rgba(138,138,138,0)  0%, rgba(138,138,138,0)  31.9%,  #da0a43 32.1%, #500a31 100%);
        height: 900px;
    }

    
}