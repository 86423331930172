.video-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 150px;
    width: 100%;
}

.video-and-text {
    display: flex;
}

.video-container .carousel {
    display: flex;
    align-items: flex-start;
}

.video-container .carousel {
    display: flex;
    align-items: flex-start;
}

.video-container .carousel .img,
.video-container .carousel .video {
    height: 20rem;
}

.video-container .carousel-video {
    float: left;
    height: 22rem;
    width: 28rem;
}

.video-container .carousel-inner {
    align-self: flex-start;
}

.video-container .carousel-indicators {
    align-self: flex-start;
    display: none;
}

.video-container .carousel-control-next-icon {
    background-image: url(elements/rehub_socrates_arrow_right_la1.svg);
    width: 4em;
    height: 3em;
    background-color: white;
    border-color: white;
    margin-left: 1em;
}


.video-container .carousel-control-next-icon:hover {
    background-color: white;
    border-color: white;
    opacity: 0.5;
}

.video-container .carousel-control-prev-icon {
    background-image: url(elements/rehub_socrates_arrow_left_la1.svg);
    width: 4em;
    height: 3em;
    background-color: white;
    border-color: white;
    margin-right: 1em;
}

.video-container .carousel-control-prev-icon:hover {
    background-color: white;
    border-color: white;
    opacity: 0.7;
}

.second-col {
    /* vertical-align: left; */
    vertical-align: baseline;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 2em;
}

.side-text {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.250rem;
    margin-top: 1rem;
    margin-left: 2rem;
    float: left;
    width: 20rem;
}

.side-text h3 {
    font-size: 1.750rem;
}

.side-text h4 {
    font-size: 1.250rem;
}

.side-text ul li::before {
    content: "\25AA";
    color: #f0734b;
    font-weight: bold;
    display: inline-block;
    width: 1.0em;
    margin-left: -1em;
}

@media (max-width: 320px) {
    .video-container .carousel-video {
        float: left;
        height: 15rem;
        width: 22rem;
    }

    .side-text {
        margin-left: 1rem;
    }

}

@media (min-width: 321px) and (max-width: 480px) {
    .video-container .carousel-video {
        float: left;
        height: 15rem;
        width: 22rem;
    }

    .side-text {
        margin-left: 1rem;
    }

    .carousel-item .container,
    .carousel-item .col {
        padding-right: 0;
        padding-left: 0;
        margin-right: 0;
        margin-left: 0;
    }

}