.policy {
    font-family: Arial, sans-serif;
    color: #3c3c3b;
    margin: 10%;
    line-height: 1.750rem;
}

.policy h3 {
    font-weight: 600;
    text-align: center;
    font-size: 1.250rem;
}

.policy p {
    text-align: justify;
}

.policy li {
    list-style: disc;
    margin : 1em;
    text-align: justify;
}