.header {
    display: flex;
    flex-direction: column;
}

.header-first-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 3%;
}

.logo {
    overflow: hidden;
    margin-left: 3%;
    margin-top: 2%;
}

.socrates-logo {
    height: 70px;
    width: 200px;
}

.top-right-container {
    display: flex;
    margin-top: 2%;
    align-items: center;
}

.languages {
    display: flex;
    margin-top: 3%;
    margin-right: 3%;
    font-family: 'Montserrat', sans-serif;
    color: white;
    align-self: center;
}

.languages h3 {
    font-size: 1rem;
}

.languages a {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    color: white;
    margin-right: 5px;
    margin-left: 5px;
    text-decoration: underline;
}

.languages a:hover {
    font-weight: bold;
    cursor: pointer;
}

.languages a:active {
    font-weight: bold;
}

.top-right-container button {
    border: none;
    width: 110px;
    text-align: center;
    background-color: #F0734B;
    font-family: 'Montserrat', sans-serif;
    color: white;
    padding: 4%;
    font-size: 1rem;
    cursor: pointer;
    white-space: nowrap;
    margin-left: 5%;
}

.top-right-container button:hover {
    background-color: #cb5f3d;
}

button>img,
button>span {
    vertical-align: middle;
}

.top-right-container button img {
    width: 20px;
    margin-left: 5px;
}

.header-second-container {
    margin-top: 3%;
    display: flex;
    justify-content: center;
    /* min-height: 50rem; */
}

.info {
    font-family: 'Montserrat', sans-serif;
    color: white;
    width: 20%;
}

.info-first-part {
    font-weight: lighter;
    font-size: 2.750rem;
}

.info-second-part {
    font-weight: 700;
    font-size: 2.75rem;
}

.info p {
    margin-top: 5%;
    font-size: 1rem;
    width: 90%;
}

.info button {
    margin-top: 10%;
    margin-bottom: 5%;
    border: none;
    text-align: center;
    background-color: #0086D9;
    font-family: 'Montserrat', sans-serif;
    color: white;
    padding: 4%;
    font-size: 1rem;
    cursor: pointer;
    white-space: nowrap;
}

.info button:hover {
    background-color: #0163a0;
}

button>span {
    vertical-align: middle;
}

.info hr {
    color: white;
    background-color: white;
    opacity: 1;
    border-color: white;
    width: 10rem;
}

.partners {
    font-style: italic;
    font-size: 0.75rem;
}

.partners img {
    height: 30px;
    margin-left: 1rem;
}

.partner-detail p {
    margin-top: 1%;
}

.header-video {
    margin-top: 25%;
}

.header-video video {
    height: 19rem;
    width: 28rem;
    object-fit: cover;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
}

button.btn.btn-primary {
    border-radius: 0.0rem;
    padding: 0.4rem;
}

button.btn.btn-primary img {
    width: 0.95rem;
    height: 0.95rem;
}

@media (max-width: 320px) {
    .socrates-logo {
        height: 40px;
        width: 120px;
    }

    .top-right-container {
        align-items: center;
        flex-direction: column;
        margin-right: 2%;
    }

    .header-second-container {
        margin-bottom: 7rem;
        flex-direction: column;
        align-items: center;
    }

    .info {
        width: 80%;
    }

    .info-first-part {
        font-size: 2rem;
    }

    .info-second-part {
        font-size: 2rem;
    }

    .info p {
        font-size: 1.125rem;
    }

    .info button {
        font-size: 0.75rem;
    }

    .header-video {
        margin-left: 3%;
    }

    .header-video video {
        width: 102%;
        justify-content: center;
        height: 85%;
        margin-top: 10%;
        box-shadow: none;
        margin-left: 0;
    }

}

@media (min-width: 321px) and (max-width: 480px) {
    .socrates-logo {
        height: 40px;
        width: 180px;
    }

    .header-first-container {
        align-items: center;
        margin-top: 5%;
    }

    .top-right-container {
        flex-direction: column;
        margin-right: 5%;
        align-items: flex-end;
    }

    .languages {
        margin-right: 0%;
        align-self: flex-end;
    }

    .top-right-container button {
        margin-left: 0%;
    }

    .header-second-container {
        flex-direction: column;
        margin-right: 1%;
        margin-top: 10%;
    }

    .info {
        width: 93%;
    }

    .info-first-part {
        font-size: 2rem;
    }

    .info-second-part {
        font-size: 2rem;
    }

    .info p {
        font-size: 0.9rem;
    }

    .header-video {
        margin-left: 3%;
        margin-top: 10%;
    }

    .header-video video {
        width: 100%;
        justify-content: center;
        height: 95%;
        margin-top: 10%;
        box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
        margin-left: 0;
    }

    .info button {
        margin-top: 5%;
    }
}

@media (min-width: 481px) and (max-width: 958px) {
    .socrates-logo {
        height: 50px;
    }

    .header-first-container {
        align-items: center;
        margin-top: 2%;
    }

    .top-right-container {
        flex-direction: column;
        margin-right: 5%;
        align-items: flex-end;
    }

    .languages {
        margin-right: 0%;
        align-self: flex-end;
    }

    .top-right-container button {
        margin-left: 0%;
    }

    .header-second-container {
        flex-direction: column;
        margin-right: 1%;
        margin-top: 10%;
    }

    .info {
        width: 93%;
    }

    .info-first-part {
        font-size: 2rem;
    }

    .info-second-part {
        font-size: 2rem;
    }

    .info p {
        font-size: 0.9rem;
    }

    .header-video {
        margin-left: 3%;
        margin-top: 5%;
    }

    .header-video video {
        width: 100%;
        justify-content: center;
        height: 95%;
        margin-top: 10%;
        box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
        margin-left: 0;
    }

    .info button {
        margin-top: 5%;
        padding: 2%;
    }
}

@media (min-width: 959px) and (max-width: 1040px) {
    .info {
        width: 50%;
    }

    .header-video {
        margin-right: 2%;
        margin-left: 2%;
        margin-top: 10%;
        width: 50%;
    }

    .header-video video {
        height: 20rem;
        width: 29rem;
        margin-top: 10%;
        box-shadow: 20px 20px 25px rgba(0, 0, 0, 0.3);
    }

    .info hr {
        margin-top: 5%;
    }

    .info button {
        margin-top: 5%;
    }
}

@media (min-width: 1041px) and (max-width: 1280px) {
    .info {
        width: 50%;
    }

    .header-video {
        margin-right: 2%;
        margin-left: 2%;
        margin-top: 10%;
        width: 50%;
    }

    .header-video video {
        height: 20rem;
        width: 32rem;
        margin-top: 10%;
        box-shadow: 20px 20px 25px rgba(0, 0, 0, 0.3);
    }

    .info hr {
        margin-top: 5%;
    }

    .info button {
        margin-top: 5%;
    }
}

@media (min-width: 1281px) {
    .info {
        width: 50%;
    }

    .header-video {
        margin-right: 2%;
        margin-left: 2%;
        margin-top: 10%;
        width: 50%;
    }

    .header-video video {
        height: 25rem;
        width: 38rem;
        margin-top: 10%;
        box-shadow: 20px 20px 25px rgba(0, 0, 0, 0.3);
    }

    .info hr {
        margin-top: 5%;
    }

    .info button {
        margin-top: 5%;
    }

    .partners span {
        font-size: 1rem;
    }

    .partner-detail p {
        font-size: 1rem;
    }

    .info button {
        padding: 2%;
    }
}

@media (min-width: 1920px) {
    .info {
        width: 40%;
    }
}