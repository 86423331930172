.email-registration {
    padding: 10%;
    display: flex;
    /* justify-content: center; */
    font-family: 'Montserrat', sans-serif;
    color: #3c3c3b;
    background-color: #F2F2F2;
}

.email-title {
    margin-right: 5%;
}

.email-registration h1 {
    /* text-align: center; */
    font-size: 2.5em;
    font-weight: 400;
}

.email-registration h2 {
    font-weight: 400;
    /* text-align: center; */
    margin-top: 5%;
}

.email-registration .early-access {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.tacho-container {
    /* display: flex;
    justify-content: left;
    align-items: flex-start; */
    text-align: left;
    margin:0px auto 0px auto;
}

.tacho {
    margin-top: 15%;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tacho-graphic {
    height: 100px;
}

.tacho h2 {
    margin-top: -45px;
    font-weight: 600;
    font-size: 3rem;
}

.tacho p {
    font-weight: 400;
    font-size: 1.375rem;
}

.email-registration input {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    color: #3c3c3b;
}

.email-phrase {
    width: 300px;
    margin-top: 5%;
    font-size: 14px;
}

.title-form {
    font-weight: 700;
    font-size: 1.125rem;
}

.form-container {
    margin-top: 2%;
}

.form {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
}

.form-names {
    display: flex;
    justify-content: space-between;
}

.form input {
    margin-top: 3%;
    border-radius: 10px;
    padding: 5px;
    border: none;
    -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.3);
}

.name, .surname {
    flex-grow: 1;
}

li {
    list-style: none;
}

label {
    margin-left: 10px;
}

label a{
    color: #3c3c3b;
    font-size: 12px;
    font-style: italic;
}

input[type="checkbox"]
{
    margin-top: 5%; 
}

.form button {
    margin-top: 5%;
    border: none;
    text-align: center;
    background-color: #0086D9;
    font-family: 'Montserrat', sans-serif;
    color: white;
    padding: 2%;
    font-size: 14px;
    width: 280px;
    cursor: pointer;
    white-space: nowrap;
}

.form table {
    margin-top: 5%;
    font-size: 14px;
    font-weight: 400;
}

.form-container .row td {
    width: auto; 
    padding: 10px
}

.arrow-right {
    height: 10px;
    width: 10px;
}
    
.quarter-circle {
    width: 200px;
    height: 200px;
    background-color: none;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    border-bottom-left-radius: 100px;
    border: 20px solid transparent;
    border-left: 20px solid green;
    border-top: 20px solid green;
    position: absolute;
    transform: rotate(-135grad);
    z-index: 3;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.stencil{
    width: 200px;
    height: 200px;
    background-color: white;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    border-bottom-left-radius: 100px;
    border: 20px solid white;
    border-left: 20px solid white;
    border-right: 20px solid white;
    border-bottom: 20px solid white;
    border-top: 20px solid white;
    position: absolute;
    transform: rotate(90grad);
    z-index: 1;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.stencil2{
    width: 200px;
    height: 100px;
    background-color: white;
    border: 20px solid transparent;
    border-left: 20px transparent;
    border-right: 20px transparent;
    border-bottom: 20px red;
    border-top: 20px red;
    margin-top: 100px;
    position: absolute;
    z-index: 5;
   
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.email-registration .alert-box{
    top: 0.5rem;
    left: 5%;
    position: fixed;
    display: block;
    z-index: 999;
    width: 90%;
    opacity: 0.95;
}

.email-registration .alert{
    position: relative;
    width: 100%;
    box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.5);
    font-size: 0.9rem;
}


@media (max-width: 320px) {
    .email-registration {
        flex-direction: column;
        align-items: center;
    }

    .form-container {
        width: 90%;
    }

    .form-names {
        flex-direction: column;
    }

    .form button {
        font-size: 0.75rem;
        width: 230px;
    }
}

@media (min-width: 321px) and (max-width: 480px) {
    .email-registration {
        flex-direction: column;
        align-items: center;
    }

    .email-registration h1 {
        font-size: 3.5em;
        font-weight: 400;
    }
    
    .email-registration h2 {
        text-align: center;
    }
    
    .email-registration .early-access {
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 3.5em;
        margin-bottom: 10%;
    }

    .email-phrase {
        font-size: 1.125rem;
        text-align: justify;
        width: 100%;
        margin-top: 10%;
    }

    .tacho-graphic {
        height: 150px;
    }

    .form-container {
        margin-top: 10%;
        width: 90%;
    }
    
    .form-names {
        flex-direction: column;
    }

    .form button {
        margin-bottom: 10%;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .email-registration {
        flex-direction: column;
        align-items: center;
    }

    .form-container {
        width: 90%;
    }

    .form-names {
        flex-direction: column;
    }

    .form button {
        margin-bottom: 10%;
    }
}

@media (min-width: 769px) and (max-width: 1280px) {
    .email-registration {
        flex-direction: column;
        align-items: center;
    }

    .form-container {
        width: 90%;
    }

    .form-names {
        flex-direction: column;
    }

    .form button {
        margin-bottom: 10%;
    }

}

@media (min-width: 1281px) {
    .form-container {
        width: 45%;
    }

    .form-names {
        gap: 30px;
    }
}

@media (min-width: 1920px) {
    .form-container {
        width: 35%;
    }

    
}