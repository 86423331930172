.resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
  /* margin: 0.5em */
}

.resp-sharing-button {
  border-radius: 5px;
  transition: 25ms ease-out;
  padding: 0.5em 0.75em;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  text-align: center;
}

.resp-sharing-button__icon img {
  width: 1em;
  height: 1em;
  margin-right: 0.4em;
  vertical-align: middle;
  display: table-cell;
}

.resp-sharing-button__icon .mail-icon {
  width: 2.95rem;
}

.button.btn.btn-primary img {
  width: 5.95rem;
  height: 5.95rem;
}

.resp-sharing-button--small img {
  margin: 0;
  vertical-align: middle;
}

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
  stroke: #fff;
  fill: none
}

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: #fff;
  stroke: none;
}

.resp-sharing-button {
  color: #3c3c3b;
  background-color: #fff;
  border-radius: 0rem;
  border: 0px solid #fff;
  text-align: center;
}

.resp-sharing-button:hover {
  background-color: #F4C7B8;
  border: 0px solid #fff;
}
