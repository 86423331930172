.slogan-container {
    width: 100%;
}

.slogan {
    font-family: 'Montserrat', sans-serif;
    color: #3c3c3b;
    margin-left: 0rem;
    margin-bottom: 3%;
}

.slogan h2:first-child {
    font-weight: 700;
    font-size: 2.25rem;
}

.slogan h2:nth-child(2) {
    font-weight: 100;
    font-size: 2.25rem;
}

.slogan h2:nth-child(4) {
    font-weight: 100;
    font-size: 2.25rem;
}

.slogan h1,
h2 {
    display: inline;
}

.gradient-txt {
    font-weight: 700;
    font-size: 3rem;
    background-image: linear-gradient(350deg, #da0a43 32.1%, #500a31 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

@media (max-width: 320px) {
    .slogan {
        /* margin-top: 10%; */
        font-size: 10px;
        width: 90%;
    }

    .gradient-txt {
        font-size: 2.25rem;
    }
}

@media (min-width: 321px) and (max-width: 480px) {
    .slogan-container {
        margin-top: 15%;
    }

    .slogan {
        font-size: 10px;
        width: 98% !important;
        word-wrap: break-word;
    }

    .slogan h2:first-child {
        font-size: 1.75rem;
    }

    .slogan h2:nth-child(2) {
        font-size: 1.75rem;
    }

    .slogan h2:nth-child(4) {
        font-size: 1.75rem;
    }

    .gradient-txt {
        font-size: 2.5rem;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .slogan-container {
        margin-top: 10%;
    }

    .slogan {
        font-size: 10px;
        justify-content: center;
    }
}

@media (min-width: 769px) and (max-width: 1280px) {
    .slogan-container {
        width: 100%;
        margin-top: 10%;
    }
}

@media (min-width: 1281px) {
    .slogan-container {
        margin-top: 10%;
        width: 90%;
    }
}

@media screen and (min-width: 1900px) {
    .slogan-container {
        margin-top: 10%;
    }
}