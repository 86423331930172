.contact {
    font-family: 'Montserrat', sans-serif;
    color: #3c3c3b;
    width: 100%;
}

.contact-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F2F2F2;
    padding-top: 5%;
    padding-bottom: 1%;
    padding-left: 10%;
    padding-right: 10%;
}

.contact-container {
    background-color: #F2F2F2;
    padding-top: 3%;
    padding-bottom: 3%;
}

.contact-container p {
    font-size: 30px;
}

.contact-container h4 {
   color: #F0734B;
   font-size: 14px;
   font-weight: 500;
   padding-top: 2%;
}

.mail-container {
    display: flex;
    flex-direction: row;
    padding-top: 1%;
}

.mail {
    height: 25px;
    width: 30px;
    margin-right: 10px;
}

.mail-container a {
    color: #3c3c3b;
}

.social-media p {
    font-size: 20px;
    padding-top: 4%;
    margin: 0;
}

.social-media-icons {
    display: flex;
    justify-content: space-between;
    width: 30%;
}

.linkedin-container {
    padding-top: 1%;
    padding-bottom: 1%;
    display: flex;
    color: #3c3c3b;
    text-decoration: underline;
    align-items: center;
}

.linkedin-container a {
    color: #3c3c3b;
}

.linkedin-container h4 {
    font-size: 14px;
}

.linkedin-icon {
    height: 30px;
    width: 30px;
    margin-right: 10px;
}

.speech-bubble img {
    height: 200px;
}

footer {
    background-color: #500A31;
    padding: 20px;
    color: white;
    display: flex;
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
    
}

.legal-pages {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 50%;
    padding-right: 10%;
}

.legal-pages h5 {
    font-style: italic;
    font-weight: 100;
    font-size: 11px;
    align-self: center;
    margin-bottom: 0;
}

.legal-pages a {
    color: white;
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    align-self: center;
}

.footer-second-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 50%;
    padding-left: 20%;
}

.footer-second-container button {
    border: none;
    width: 110px;
    text-align: center;
    background-color: #F0734B;
    font-family: 'Montserrat', sans-serif;
    color: white;
    /* padding: 4%; */
    font-size: 14px;
    cursor: pointer;
    white-space: nowrap;
    margin-left: 5%;
}

.footer-second-container button img {
    width: 20px;
    margin-left: 5px;
}

.footer-second-container button:hover {
    background-color: #cb5f3d;
}

.footer-second-container h5 {
    font-style: italic;
    font-size: 12px;
    font-weight: 200;
    align-self: center;
    margin-bottom: 0;
    white-space: nowrap;
}

button>img,
button>span {
    vertical-align: middle;
}

.product-by-rehub {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rehub-forge-logo{
    height: 40px;
    margin-left: 5%;
}

@media (max-width: 320px) {
    .contact-info {
        padding-top: 10%;
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .speech-bubble img {
        height: 150px;
        margin-bottom: 10%;
    }

    .contact-container p {
        font-size: 24px;
    }

    .social-media p {
        margin-top: 10%;
        font-size: 24px;
    }

    footer {
        flex-direction: column-reverse;
    }

    .legal-pages h5 {
        align-self: baseline;
    }

    .footer-second-container {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 0%;
    }

    .product-by-rehub {
        margin-top: 20%;

    }

    .legal-pages {
        align-items: flex-start;
        flex-direction: column;
        margin-top: 10%;
        width: 100%;
    }

    .legal-pages a {
        align-self: flex-start;
        display: inline-block;
    }
}


@media (min-width: 321px) and (max-width: 480px) {
    .contact-info {
        padding-top: 10%;
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .speech-bubble img {
        height: 150px;
        margin-bottom: 10%;
    }

    .contact-container p {
        font-size: 24px;
    }

    .social-media p {
        margin-top: 10%;
        font-size: 24px;
    }

    footer {
        flex-direction: column-reverse;
    }

    .legal-pages h5 {
        align-self: baseline;
    }

    .footer-second-container {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 0%;
    }

    .product-by-rehub {
        margin-top: 20%;

    }

    .legal-pages {
        align-items: flex-start;
        flex-direction: column;
        margin-top: 10%;
        width: 100%;
    }

    .legal-pages a {
        align-self: flex-start;
        display: inline-block;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .contact-info {
        padding-top: 10%;
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .speech-bubble img {
        height: 150px;
        margin-bottom: 10%;
    }

    .contact-container p {
        font-size: 24px;
    }

    .social-media p {
        margin-top: 10%;
        font-size: 24px;
    }

    footer {
        flex-direction: column-reverse;
    }

    .legal-pages h5 {
        align-self: baseline;
    }

    .footer-second-container {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 0%;
    }

    .product-by-rehub {
        margin-top: 20%;

    }

    .legal-pages {
        align-items: flex-start;
        flex-direction: column;
        margin-top: 10%;
        width: 100%;
    }

    .legal-pages a {
        align-self: flex-start;
        display: inline-block;
    }

}


@media (min-width: 769px) and (max-width: 958px) {
    .legal-pages {
        display: block;
    }
    
    .legal-pages a {
        padding-right: 20px;
    }
    
    .footer-second-container {
        padding-left: 0;
    }

    .footer-second-container h5 {
        font-size: 10px;
    }

    .rehub-forge-logo{
        height: 30px;
    }
}

@media (min-width: 959px) and (max-width: 1280px) {
    .footer-second-container {
        padding-left: 10%;
    }

    .legal-pages {
        padding-right: 0;
    }

}

@media (min-width: 1281px) and (max-width: 1500px) {
    .footer-second-container {
        padding-left: 10%;
    }
}

@media screen and (min-width: 1900px) {
   
  }