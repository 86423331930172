.video-and-text-container {
    margin-bottom: 10%;
}

.video-and-text {
    padding-top: 10%;
}

.video-slides {
    height: 25rem;
    box-shadow: 0px 0px 12px 0px rgba(6, 6, 6, 0.2);
}

.arrow-videos {
    transform: rotate(90deg);
    height: 3rem;
    margin-bottom: 35%;
}

.bulletpoints {
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
}

.bulletpoints.first {
    margin-left: 5%;
}

.bulletpoints.second {
    margin-right: 5%;
}

.bulletpoints.third {
    margin-left: 5%;
}

.bulletpoints h3 {
    font-size: 1.50rem;
    font-weight: 700;
}

.bulletpoints hr {
    width: 10rem;
    margin-top: 7%;
    margin-bottom: 7%;
}


.bulletpoints ul {
    margin: 0;
    padding: 0;
}

.bulletpoints ul li {
    list-style-image: url(./elements/rehub_socrates_list_symbol_la1.svg);
    line-height: 2;
    list-style-position: inside;
}

@media (max-width: 320px) {
    .video-and-text {
        flex-direction: column;
    }

    .video-slides {
        width: 100%;
        justify-content: center;
        height: 96%;
        margin-top: 10%;
        margin-left: 0;
    }

    .arrow-videos {
        margin-top: 10%;
        margin-bottom: 10%;
    }

    .bulletpoints.first {
        margin-left: 0;
    }

    .bulletpoints.second {
        margin-right: 0;
    }

    .bulletpoints.third {
        margin-left: 0;
    }

    .video-and-text.second {
        flex-direction: column-reverse;
    }
}

@media (min-width: 321px) and (max-width: 480px) {
    .video-and-text {
        flex-direction: column;
    }

    .video-slides {
        width: 100%;
        justify-content: center;
        height: 96%;
        margin-left: 0;
    }

    .arrow-videos {
        margin-top: 10%;
        margin-bottom: 10%;
    }

    .bulletpoints.first {
        margin-left: 0;
    }

    .bulletpoints.second {
        margin-right: 0;
    }

    .bulletpoints.third {
        margin-left: 0;
    }

    .video-and-text.second {
        flex-direction: column-reverse;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .video-and-text {
        flex-direction: column;
    }

    .video-slides {
        width: 100%;
        justify-content: center;
        height: 96%;
        margin-top: 0;
        margin-left: 0;
    }

    .arrow-videos {
        margin-top: 10%;
        margin-bottom: 10%;
    }

    .bulletpoints.first {
        margin-left: 0;
    }

    .bulletpoints.second {
        margin-right: 0;
    }

    .bulletpoints.third {
        margin-left: 0;
    }

    .video-and-text.second {
        flex-direction: column-reverse;
    }
}

@media (min-width: 769px) and (max-width: 1280px) {

    .video-and-text {
        flex-direction: column;
    }

    .video-slides {
        width: 100%;
        justify-content: center;
        height: 96%;
        margin-top: 0;
        margin-left: 0;
    }

    .arrow-videos {
        margin-top: 10%;
        margin-bottom: 10%;
    }

    .bulletpoints.first {
        margin-left: 0;
    }

    .bulletpoints.second {
        margin-right: 0;
    }

    .bulletpoints.third {
        margin-left: 0;
    }

    .video-and-text.second {
        flex-direction: column-reverse;
    }
 
}

@media (min-width: 1281px) {
 
}

@media screen and (min-width: 1900px) {
    .arrow-videos {
        margin-bottom: 25%;
    }
}  